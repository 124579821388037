export const Api = () => {
    return (
        <div className='apipagecontent'>
            <h1 className="h1header">API Documentation</h1>

            <section className="overview">
                <h2 className="h2header">Overview</h2>
                <p>
                    The KarutaMarket API allows you to fetch information about item frames,
                    including their values and associated tags. This is a simple way to integrate
                    frame data into your own applications.
                </p>
            </section>

            <section section className="using">
                <h2 className="h2header">How to Use</h2>
                <p>
                    To call the API, use the following format: https://karutamarket.com/api/item/frame+name
                </p>
                <p>
                    Replace <code>frame+name</code> with the frame's name, using <code>+</code> 
                    instead of spaces. For example: https://karutamarket.com/api/item/archlight+frame
                </p>
            </section>

            <section section className="response">
                <h2 className="h2header">Response</h2>
                <p>The API will return a JSON object with the following structure:</p>
                <code>
                    {`{
                    "id": "archlight frame",
                    "low": 45,
                    "high": 60,
                    "tags": ["transparent", "special"]
                    }`}
                </code>
            </section>

            <section section className="features">
                <h2 className="h2header">Available Features</h2>
                <ul>
                    <li>Retrieve frame value information</li>
                    <li>Retrieve tags associated with frames</li>
                </ul>
            </section>

            <footer className="apifooter">
                    For any questions or support, please contact me on Discord at toottootx.
            </footer>
        </div>
    );
  };
  