import * as React from "react";
import './App.css';
//import Axios from "axios";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { FrameInfo } from './pages/FrameInfo';
import { About } from './pages/About';
import { Navbar } from "./Navbar";
import { Api } from "./pages/Api";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import { MorphGallary } from "./pages/morphGallary";

function App() {
  //defualtoptions has lots of configurations
  const client = new QueryClient();
  return (
    <div className="App">
      <QueryClientProvider client={client}>
        <Router>
          <div id="content">
            <header>
              <div className="title">

              </div>
            </header>
            {/* THIS NAVBAR BELOW IS A COMPONENT. SO THE NAVBAR IS GETTING IMPORTED FROM NAVBAR.JS */}
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/morphgallary" element={<MorphGallary />} />
              <Route path="/frameinfo" element={<FrameInfo />} />
              <Route path="/about" element={<About />} />
              <Route path="/api" element={<Api />} />
              <Route path="*" element={<h1>PAGE NOT FOUND</h1>} />
            </Routes>
            {/* <div>footer</div> */}
          </div>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;


// props template   //
// const CardInfo = (props) => {
//   return (
//     pass
//   )
// }

// practiedc with lists in jsx
// function App() {
//   const frames = ["Interface Frame", "Hacker Frame", "Infinity Void Frame", "Mirrored Energy frame"];



// practice with objects and component    //
// function App() {
//   const frames = [
//     {name:"Interface Frame", cost: 25},
//     {name:"Hacker Frame", cost: 45},
//     {name:"Infinity Void Frame", cost: 65},
//     {name:"Mirrored Energy frame", cost: 105}
//   ];


//   return (
//     <div className="App">
//       {frames.map((frame, key) =>{
//         return <Frame name={frame.name} cost={frame.cost}/>
//       })}
//     </div>
//   );
// }

// const Frame = (props) => {
//   return (
//     <div>
//       {props.name} {props.cost}
//     </div>
//   );
// };

//   return (
//     <div className="App">
//       {frames.map((frameName, key) =>{
//         return <h1 key={key}> {frameName}</h1>
//       })}
//     </div>
//   );
// }



// practice conditionals jsx //
// function App() {
//   const cardLP = 17;
//   const cardHP = false;

//   return (
//     <div className="App">
//       {cardLP >= 15 ? <h1>EXPENSIVE CARD</h1> : <h1>CHEAP</h1>}
//       <h1 style={{color: cardHP ? "green" : "red"}}>EXPENSIVE</h1>
//       {cardHP && <button> THIS IS A BUTTON </button>}
//     </div>
//   );
// }





// simple fetching data using fetch   //
// fetch("https://catfact.ninja/fact").then((fact) => fact.json()).then((data) => {
//   console.log(data)
// });





// other way to fetch data using axios //
// function App() {
//   const [catFact, setCatFact] = useState("");

//   const fetchCatFact = () => {
//     Axios.get("https://catfact.ninja/fact").then((res) => {
//       setCatFact(res.data.fact);
//     });
//   };

//   useEffect(() => {
//     fetchCatFact();

//   }, []);
  
  
//   return (
//     <div className="App">
//       <button onClick={fetchCatFact}>Generate Cat Fact</button>
//       <p>{catFact}</p>
//     </div>
//   );
// }

//starter code   //
/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */