import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { formatName } from '../formatName';
import MyImageComponent from '../getImagePath';
import InfiniteScroll from 'react-infinite-scroll-component';
// import ModalComponent from '../Modal';
import '../App.css';

export const Home = () => {
    const { data, isLoading, isError, error } = useQuery({
        queryKey: ['Frames'],
        queryFn: async () => {
            try {
                const response = await fetch('https://raw.githubusercontent.com/toottootx/BGBerry-JSONs/main/sortedFrames.json');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return await response.json();
            } catch (error) {
                throw error; // rethrow the error to be caught by React Query's error handling
            }
        }
    });

    const [visibleItems, setVisibleItems] = useState(20);  // initial number of items
    const [filters, setFilters] = useState([]);  // filter state
    const [minPrice, setMinPrice] = useState('');  // minimum price filter
    const [maxPrice, setMaxPrice] = useState('');  // maximum price filter
    const [searchName, setSearchName] = useState('');  // maximum price filter

    const fetchMoreData = () => {
        setVisibleItems(prev => prev + 30);  // load more items
    };

    const handleFilterChange = (event) => {
        const { value, checked } = event.target;
        // console.log(`Filter changed: ${value}, Checked: ${checked}`);
        setFilters(prevFilters => 
            checked ? [...prevFilters, value] : prevFilters.filter(filter => filter !== value)
        );
        setVisibleItems(50);  // reset visible items when filter changes
    };

    const handlePriceChange = (event) => {
        const { name, value } = event.target;
        if (name === 'minPrice') {
            setMinPrice(value);
        } else {
            setMaxPrice(value);
        }
        setVisibleItems(50);  // reset visible items when filter changes
    };

    const handleSearchName = (event) => {
        setSearchName(event.target.value);
        setVisibleItems(50);  // reset visible items when filter changes
    };

    const filteredData = data && Object.entries(data).filter(([name, item]) => {
        const matchesTags = filters.length === 0 || (item.tags && filters.every(filter => item.tags.includes(filter)));
        const matchesMinPrice = minPrice === '' || item.low >= parseFloat(minPrice);
        const matchesMaxPrice = maxPrice === '' || item.high <= parseFloat(maxPrice);
        const matchesName = searchName === '' || name.toLowerCase().includes(searchName.toLowerCase());
        return matchesTags && matchesMinPrice && matchesMaxPrice && matchesName;
    });

    // console.log('Filtered Data:', filteredData);

    const [sidebarVisible, setSidebarVisible] = useState(false);

    const handleSidebarToggle = () => {
        setSidebarVisible(!sidebarVisible);
    };

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error: {error.message}</div>;

    return (
        <div className='homepagecontent'>

            <div className='filter-toggle' onClick={handleSidebarToggle}>
                <aside className={`sidebar ${sidebarVisible ? 'visible' : ''}`}>
                    <h3 className='filter-title'>Filter</h3>
                    <form className='filterForm'>
                        <input
                            type="text"
                            id="searchName"
                            name="searchName"
                            value={searchName}
                            onChange={handleSearchName}
                            placeholder="Search Frame"
                            className='filterTextItem'
                        />

                        <input
                            type="number"
                            id="minPrice"
                            name="minPrice"
                            value={minPrice}
                            onChange={handlePriceChange}
                            placeholder="Min Price"
                            className='filterTextItem'
                        />

                        <input
                            type="number"
                            id="maxPrice"
                            name="maxPrice"
                            value={maxPrice}
                            onChange={handlePriceChange}
                            placeholder="Max Price"
                            className='filterTextItem'
                        />

                        <label>
                        <div className='filterItemWrapper'>
                        <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="transparent" name="transparent" value="transparent" />
                        <label htmlFor="transparent"> Transparent</label>
                        </div>
                        </label>

                        <label htmlFor="special">
                        <div className='filterItemWrapper'>
                        <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="special" name="special" value="special" />
                        <label htmlFor="special"> Special</label>
                        </div>
                        </label>

                        <label htmlFor="carousel">
                        <div className='filterItemWrapper'>
                        <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="carousel" name="carousel" value="carousel" />
                        <label htmlFor="carousel"> Carousel</label>
                        </div>
                        </label>

                        <label htmlFor="event">
                        <div className='filterItemWrapper'>
                        <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="event" name="event" value="event" />
                        <label htmlFor="event"> Event</label>
                        </div>
                        </label>

                        <label htmlFor="bit">
                        <div className='filterItemWrapper'>
                        <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="bit" name="bit" value="bit" />
                        <label htmlFor="bit"> Bit</label>
                        </div>
                        </label>

                        <label htmlFor="dojo">
                        <div className='filterItemWrapper'>
                        <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="dojo" name="dojo" value="dojo" />
                        <label htmlFor="dojo"> Dojo</label>
                        </div>
                        </label>

                        <label htmlFor="monthly">
                        <div className='filterItemWrapper'>
                        <input className='filterItem' onChange={handleFilterChange} type="checkbox" id="monthly" name="monthly" value="monthly" />
                        <label htmlFor="monthly"> Monthly</label>
                        </div>
                        </label>

                    </form>
                </aside>
            </div>

            <main id="infiniteScrollContainer">
                <InfiniteScroll
                    dataLength={visibleItems}
                    next={fetchMoreData}
                    hasMore={visibleItems < filteredData.length}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="infiniteScrollContainer"
                    className='scrollableArea'
                >
                    {filteredData.slice(0, visibleItems).map(([name, { low, high }]) => (
                        <div key={name} className='scroll-item'>
                            <h2 id='framename'>{formatName(name)}</h2>
                            <div className="image-container">
                                <MyImageComponent
                                itemName={name} 
                                />
                            </div>
                            <div className='prices-text'>
                                <div className='low-price'>Low: 🎟️{low}</div>
                                <div className='high-price'>High: 🎟️{high}</div>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
            </main>

            <footer className="footer">
                <section>
                    <a href="/api">API</a>
                </section>
                <section>
                    <a href="https://hatsune.me/support" target='_blank' rel="noopener noreferrer">Partner Server</a>
                </section>
                <section>
                    <a href="https://guns.lol/toottoot"  target='_blank' rel="noopener noreferrer">Contact</a>
                </section>
            </footer>
        </div>


    );
}
